import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import AdSense from "react-adsense";
import { Helmet } from "react-helmet-async";
import Mailchimp from "../components/subscribe";
import { fetchArticles } from "../utils/api";
import { useNavigate } from 'react-router-dom';
import { parseUTC } from "../utils/helper";
import BannerAd from "../components/ads";

const CategoryArticles = ({ id }) => {
    const navigate = useNavigate();
    const [articles, setArticles] = useState(null);

    const goToArticle = (slug) => {
        navigate("/article/" + slug);
    };

    useEffect(() => {
        const getArticles = async () => {
            try {
                const response = await fetchArticles({_embed: true, categories: id, per_page: 6});
                setArticles(response);
            } catch (error) {
                console.log(error);
            }
        };
        getArticles();
    }, [id]);

    return (
        <>
            {articles == null ? (
                <Skeleton containerClassName="d-flex flex-row flex-nowrap" inline height={200} width={320} count={3} />
            ) : (
                <>
                    {articles.map((article, index) => (
                        <React.Fragment key={article.id || index}>
                            <Col className="px-1 pb-2">
                                <Card className="h-100 mb-1" style={{width: "320px"}}>
                                    <div style={{height: "200px"}}>
                                        <Card.Img src={article.imgSmall} alt="standard" className="object-fit-cover w-100 h-100 cursor-pointer" onClick={() => goToArticle(article.slug)} />
                                    </div>
                                    <Card.Body onClick={() => goToArticle(article.slug)} className="pt-2 px-1 pb-0">
                                        <Card.Title className="roboto fs-6" dangerouslySetInnerHTML={{ __html: article.title.rendered }} />
                                        <Card.Subtitle className="mb-2 text-muted">{article.author.name} - {parseUTC(article.date)}</Card.Subtitle>
                                        <Card.Text className="line-clamp-4" dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }} />
                                    </Card.Body>
                                </Card>
                            </Col>
                            { ( index === 0 || index === 1 ) && ( <Col md={0}><div className="vr h-100"></div></Col> )}
                        </React.Fragment>
                    ))}
                </>
            )}
        </>
    );
};

const CATEGORIES = [
    {id: 2317, name: "Local"},
    {id: 3, name: "National"},
    {id: 7, name: "World"},
    {id: 949, name: "Opinion"},
    {id: 2853, name: "Culture"},
];

const Home = () => {
    const navigate = useNavigate();
    const [highlights, setHighlights] = useState(null);

    useEffect(() => {
        const getArticles = async () => {
            try {
                const articles = await fetchArticles({_embed: true, per_page: 8});
                setHighlights(articles);
            } catch (error) {
                console.log(error);
            }
        };
        getArticles();
    }, []);

    const goToArticle = (slug) => {
        navigate("/article/" + slug);
    }
    const handleReadMore = () => {
        navigate("/highlights");
    }
    const goToCategory = (id) => {
        navigate("/category/:id=" + id);
    }

    return (
        <>
            <Helmet>
                <title>The Yale Politic - Home</title>
            </Helmet>

            <Container fluid className="my-0 py-2 px-xs-2 px-md-5 border-bottom border-grey" style={{position: 'relative'}}>
                <Row className="mt-0">
                    <Col className="d-flex flex-column align-items-center justify-content-between mb-5" xs={{ order: 2 }} md={{ order: 1 }} style={{ minWidth: "200px" }}>
                        {highlights === null ? (
                            <Skeleton inline height={200} width={250} count={2} />
                        ) : (
                            <>
                                { highlights.slice(2, 4).map((article, index) => (
                                    <Card className="mb-3 w-100" key={index}>
                                        <Card.Img variant="top" src={article.imgSmall} onClick={() => goToArticle(article.slug)} />
                                        <Card.Body className="p-0">
                                            <Card.Title as="h4" onClick={() => goToArticle(article.slug)} dangerouslySetInnerHTML={{ __html: article.title.rendered }}></Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{article.author.name} - {parseUTC(article.date)}</Card.Subtitle>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </>
                        )}
                    </Col>

                    <Col className="align-items-top justify-content-center mb-5" xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} style={{ minWidth: "200px" }}>
                        {highlights === null ? (
                            <>
                                <Skeleton height={300} count={1} />
                                <Skeleton height={50} count={1} />
                                <Skeleton height={20} count={5} />
                            </>
                        ) : (
                            <Card className="w-100">
                                <Card.Img variant="cover" src={highlights[1].imgSmall} onClick={() => goToArticle(highlights[1].slug)} />
                                <Card.Body className="p-0 text-center">
                                    <Card.Title as="h1" onClick={() => goToArticle(highlights[1].slug)} dangerouslySetInnerHTML={{ __html: highlights[1].title.rendered }}></Card.Title>
                                    <Card.Text className="px-2" dangerouslySetInnerHTML={{ __html: highlights[1].excerpt.rendered }}></Card.Text>
                                    <Card.Subtitle className="mb-2 text-muted">{highlights[1].author.name} - {parseUTC(highlights[1].date)}</Card.Subtitle>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                    <Col className="d-flex flex-column align-items-center justify-content-between mb-5" xs={{ order: 3 }} md={{ order: 3 }} style={{ minWidth: "200px" }}>
                        {highlights === null ? (
                            <Skeleton inline height={100} width={250} count={4} />
                        ) : (
                            <>
                                { highlights.slice(4, 8).map((article, index) => (
                                    <Card className="mb-3 w-100 d-flex flex-row justify-content-between" key={index}>
                                        <Card.Body className="p-0">
                                            <Card.Title as="h6" onClick={() => goToArticle(article.slug)} dangerouslySetInnerHTML={{ __html: article.title.rendered }}></Card.Title>
                                            <Card.Subtitle className="mb-2 text-muted">{article.author.name} - {parseUTC(article.date)}</Card.Subtitle>
                                        </Card.Body>
                                        <Card.Img variant="right" src={article.imgThumbnail} onClick={() => goToArticle(article.slug)} />
                                    </Card>
                                ))}
                                <Button variant="white" className="fst-italic" onClick={handleReadMore}>Read More</Button>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{height: "35vw", minHeight: "400px"}} className="d-flex flex-row p-0 m-0 border-bottom border-grey align-items-center justify-content-center bg-dark">
                <Container fluid className="p-0 m-0 position-relative pb-50 overflow-hidden max-width-100 h-100">
                    <iframe
                        title="issuu"
                        className="position-absolute border-none w-100 h-100 left-0 right-0 top-0 bottom-0 p-0 m-0"
                        allow="clipboard-write"
                        frameBorder="0"
                        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
                        allowFullScreen
                        src="https://e.issuu.com/embed.html?d=issue_iv_final_me&u=theyalepolitic">
                    </iframe>
                </Container>
            </Container>

            <Container fluid className="px-md-5 px-2">
                {CATEGORIES.map(({id, name}) => (
                    <Row key={id} className="d-flex flex-row py-5 justify-content-left align-items-top">
                        <Col className="pb-4" md={1} style={{width: "13iss0px"}}>
                            <Button variant="white" className="m-0 py-3 fs-4" style={{width: "120px"}} onClick={() => goToCategory(id)}>
                                {name}
                            </Button>
                        </Col>
                        <Col md={12} lg={10}>
                            <Row className="scrollbar overflow-x-scroll overflow-y-hidden m-0 d-flex flex-row flex-nowrap">
                                <CategoryArticles id={id} />
                            </Row>
                        </Col>
                    </Row>
                ))}
                {/* Sidebar for Vertical Ad */}

            </Container>

            <Mailchimp />

            <div className="adsense-container d-flex justify-content-center align-items-center">
                <AdSense.Google
                    client="ca-pub-6983293802174566"
                    slot="2769088860"
                    style={{ height: "50px", width: "70%" }}
                />
            </div>
        </>
    );
};

export default Home;
